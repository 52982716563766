// error code syntax:
// 5 digits with 2 first to indicate the category and last 3 the specific case

// user status error
exports.UNKNOWN_STATUS_ERROR = "0100"
exports.NO_PLAN_ACTIVE = "0101"
exports.NOT_PURCHASED = "0102"
exports.LOGIN_REQUIRED = "0104"
exports.PROFILE_NOT_PROVIDED = "0105"

// permissions
exports.PERMISSION_DENIED = "0200"

// payment error
exports.UNKNOWN_PAYMENT_ERROR = "0300"
exports.SUBSCRIPTION_FAILED = "0301"
exports.CHECKOUT_FAILED = "0302"
exports.INSUFFICIENT_FUNDS = "0303"
exports.THREED_SECURE_FAILED = "0304"
exports.ALREADY_PURCHASED = "0305"

exports.COUPON_NOT_STARTED = "0310"
exports.COUPON_ENDED = "0311"
exports.COUPON_CONSUMED = "0312"
exports.COUPON_WRONG_OWNER = "0313"
exports.COUPON_WRONG_ITEM = "0314"
exports.COUPON_NOT_FOUND = "0315"
exports.COUPON_ALDREADY_REDEEMED = "0316"

// content error
exports.UNKNOWN_CONTENT_ERROR = "0400"
exports.MAX_IMAGE_SIZE_EXCEED = "0401"

// certification error
exports.EXAM_ALREADY_PASSED = "0501"
exports.EXAM_COOLDOWN_LOCK = "0502"
exports.EXAM_TIME_EXCEEDED = "0503"
exports.EXAM_TIMEOUT = "0504"
exports.EXAM_NOT_PASSED_YET = "0505"

exports.CERTIFICATION_SHIPPED = "0510"
exports.CERTIFICATION_SHIPPING_ALREADY_REQUIRED = "0511"
