import React, { useMemo } from "react"
import { Alert, Button, Col, Result, Row, Skeleton } from "antd"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import { devLog, getErrorMessageByCode, translatePath } from "../utils"
import "./ErrorScreen.less"

import logo from "../../static/assets/images/logo-completo.svg"
import { useBrowserDetector } from "../hooks/useBrowserDetector"
import { useTranslation } from "react-i18next"
import {
  CHECKOUT_FAILED,
  EXAM_COOLDOWN_LOCK,
  INSUFFICIENT_FUNDS,
  LOGIN_REQUIRED,
  NO_PLAN_ACTIVE,
  NOT_PURCHASED,
  PROFILE_NOT_PROVIDED,
  SUBSCRIPTION_FAILED,
} from "../../functions/lib/error-codes"

const ErrorScreen = ({ error, layout, learnMoreUrl, noAction = false }) => {
  devLog({ error })

  const { t } = useTranslation()

  const { isSSR } = useBrowserDetector()

  const errorElement = useMemo(() => {
    if (isSSR()) {
      return <Skeleton active round />
    }

    if (layout === "compact") {
      return (
        <Alert
          message="Error"
          description={error.message}
          type="error"
          showIcon
        />
      )
    }

    switch (error.message) {
      case NO_PLAN_ACTIVE:
        return (
          <Result
            title={getErrorMessageByCode(NO_PLAN_ACTIVE)}
            icon={<img width={300} src={logo} alt="logo" />}
            extra={
              <div className="error-screen-wrapper">
                <h2>{t("message:subscribeNowAndSignIn")}</h2>
                <Button type="primary" size="large" className="orange">
                  <Link to={translatePath("/subscribe")}>
                    {t("button:subscribeNow")}
                  </Link>
                </Button>
                <p className="oppure-p">{t("message:or")}</p>
                <Button
                  type="link"
                  size="large"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {t("button:goBack")}
                </Button>
              </div>
            }
          />
        )
      case NOT_PURCHASED:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            extra={
              <div className="error-screen-wrapper">
                <h2>{getErrorMessageByCode(NOT_PURCHASED)}</h2>
                <Button
                  type="link"
                  size="large"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {t("button:goBack")}
                </Button>
              </div>
            }
          />
        )
      case LOGIN_REQUIRED:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            title={null}
            extra={
              <div className="error-screen-wrapper">
                <h2>{t("message:signInToContinue")}</h2>
                <Button type="primary" size="large">
                  <Link
                    to={`${translatePath(
                      "/auth/sign-in"
                    )}?redirect=${encodeURIComponent(window.location.href)}`}
                  >
                    {t("button:signInNow")}
                  </Link>
                </Button>
              </div>
            }
          />
        )
      case "Login required":
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            title={null}
            extra={
              <div className="error-screen-wrapper">
                <h2>{t("message:signInToContinue")}</h2>
                <Button type="primary" size="large">
                  <Link
                    to={`${translatePath(
                      "/auth/sign-in"
                    )}?redirect=${encodeURIComponent(window.location.href)}`}
                  >
                    {t("button:signInNow")}
                  </Link>
                </Button>
              </div>
            }
          />
        )
      case PROFILE_NOT_PROVIDED:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            extra={
              <div className="error-screen-wrapper">
                <h2>{getErrorMessageByCode(PROFILE_NOT_PROVIDED)}</h2>
                <Button type="primary" size="large">
                  <Link to={`${translatePath("/auth/sign-up")}`}>
                    {t("button:completeProfileNow")}
                  </Link>
                </Button>
              </div>
            }
          />
        )
      case SUBSCRIPTION_FAILED:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            title={null}
            extra={
              <div className="error-screen-wrapper px-black">
                <h2>{t("error:subscriptionFailMessage1")}</h2>
                <p>{t("error:subscriptionFailMessage2")}</p>
                <p>{t("error:subscriptionFailMessage3")}</p>
                <Button type="primary" size="large" className="green">
                  <Link to={translatePath("/subscribe")}>
                    {t("button:retry")}
                  </Link>
                </Button>
              </div>
            }
          />
        )
      case CHECKOUT_FAILED:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            extra={
              <div className="error-screen-wrapper">
                <h2>{t("error:checkoutFailedMessage1")}</h2>
                <Button
                  type="primary"
                  size="large"
                  className="green"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {t("button:retry")}
                </Button>
              </div>
            }
          />
        )
      case INSUFFICIENT_FUNDS:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            title={null}
            extra={
              <div className="error-screen-wrapper">
                <h2>{t("error:insufficientFunds")}</h2>
                <Button
                  type="primary"
                  size="large"
                  className="green"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {t("button:retry")}
                </Button>
              </div>
            }
          />
        )
      case EXAM_COOLDOWN_LOCK:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            title={t(`error:${error.message}`)}
            extra={
              <div className="error-screen-wrapper">
                <h2>{t(`error:examCoolDownLockedExplain`)}</h2>
                <Button type="primary">
                  <Link to={learnMoreUrl}>{t("button:learnMore")}</Link>
                </Button>
                <p>{t("message:or")}</p>
                <Button type="primary">
                  <Link to={"/"}> {t("button:goHome")}</Link>
                </Button>

                {process.env.NODE_ENV === "development" && (
                  <textarea rows="20" style={{ width: "100%", color: "black" }}>
                    {error.stack}
                  </textarea>
                )}
              </div>
            }
          />
        )
      default:
        return (
          <Result
            icon={<img width={300} src={logo} alt="logo" />}
            title={t(`${error.message}`)}
            extra={
              <div className="error-screen-wrapper">
                <h2>{t(`error:${error.message}`)}</h2>
                {!noAction && (
                  <>
                    {learnMoreUrl && (
                      <>
                        <Button type="primary">
                          <Link to={learnMoreUrl}>{t("button:learnMore")}</Link>
                        </Button>
                        <br />
                        <br />
                        <p>{t("message:or")}</p>
                      </>
                    )}
                    <Button type="primary">
                      <Link to={"/"}> {t("button:goHome")}</Link>
                    </Button>
                  </>
                )}

                {process.env.NODE_ENV === "development" && (
                  <textarea rows="20" style={{ width: "100%", color: "black" }}>
                    {error.stack}
                  </textarea>
                )}
              </div>
            }
          />
        )
    }
  }, [error, layout])

  if (layout === "compact") {
    return errorElement
  }

  return (
    <div>
      {errorElement}
      <br />
      <Row justify={"center"}>
        <Col>
          <p className="error-extra">
            {t("message:supportHint")}
            <br />
            {t("message:or")}{" "}
            <Link style={{ color: "#98be11" }} to={translatePath("/help")}>
              {t("button:helpDesk")}
            </Link>
          </p>
        </Col>
      </Row>
      {/*<div*/}
      {/*  style={{*/}
      {/*    padding: 10,*/}
      {/*    background: "#e6f7ff",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <span>*/}
      {/*    In caso di difficoltà puoi rivolgerti direttamente al servizio*/}
      {/*    assistenza andando su{" "}*/}
      {/*    <Link style={{ color: "#98be11" }} to="/help">*/}
      {/*      Help Desk*/}
      {/*    </Link>*/}
      {/*  </span>*/}
      {/*</div>*/}
    </div>
  )
}

ErrorScreen.propTypes = {
  error: PropTypes.object.isRequired,
  layout: PropTypes.oneOf(["full", "compact"]),
}

ErrorScreen.default = {
  layout: "full",
}

export default ErrorScreen
